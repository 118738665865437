<template>
  <div>
    <div
      id="view-referral-details-modal"
      class="modal"
      tabindex="-1"
      role="dialog"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <form @submit.prevent="update">
            <div class="modal-body">
              <table class="table table-bordered" style="">
                <tbody>
                  <tr>
                    <td width="30%">Referral No.</td>
                    <th>
                      {{ referral.id }}
                    </th>
                  </tr>
                  <tr>
                    <td>Ticket No.</td>
                    <th>
                      {{ referral.ticket_id }}
                    </th>
                  </tr>
                  <tr>
                    <td>Requested by</td>
                    <th colspan="" class="">
                      {{ referral.creator_first_name }}
                      {{ referral.creator_last_name }}
                    </th>
                  </tr>
                  <tr>
                    <td>Unit</td>
                    <th colspan="" class="">
                      {{ referral.unit }}
                    </th>
                  </tr>
                  <tr>
                    <td>Service Type</td>
                    <th colspan="" class="">
                      {{ referral.type }}
                    </th>
                  </tr>
                  <tr>
                    <td>Date Requested</td>
                    <th colspan="" class="">
                      {{ referral.date_requested | dateTime }}
                    </th>
                  </tr>
                  <tr>
                    <td>Description</td>
                    <th colspan="">
                      {{ referral.description }}
                    </th>
                  </tr>
                </tbody>
              </table>

              <div class="form-group">
                <label for="">Remarks</label>
                <textarea
                  v-model="referral.remarks"
                  name=""
                  class="form-control"
                  id=""
                  placeholder=""
                  rows="3"
                  :disabled="referral.status === 'Closed'"
                ></textarea>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="submit"
                class="btn btn-primary"
                :disabled="isLoading"
                v-if="referral.status !== 'Closed'"
              >
                {{ isLoading ? "Submitting..." : "Update" }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreateTicketFormModal",
  data() {
    return {
      referral: new Form({
        id: "",
        ticket_id: "",
        creator_last_name: "",
        creator_first_name: "",
        type: "",
        unit: "",
        data_requested: "",
        description: "",
        remarks: "",
        status: "",
      }),

      isLoading: false,
    };
  },
  methods: {
    open(referral) {
      this.referral.fill(referral);
      $("#view-referral-details-modal").modal("toggle");
    },
    update() {
      this.isLoading = true;
      axios
        .put(
          "api/support/job-order-referrals/" + this.referral.id,
          this.referral
        )
        .then((res) => {
          toastr.success(res.data.message, "Information");
          this.$emit("updateTable");
          $("#view-referral-details-modal").modal("toggle");
          this.form.reset();
        })
        .catch((error) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
