<template>
  <div class="mx-auto">
    <div class="d-flex justify-content-between align-items-center px-3 mb-3">
      <div>
        <h5 class="mb-1">Job Order Referrals</h5>
      </div>
    </div>
    <CCard class="rounded shadow-sm">
      <CCardBody class="px-0">
        <div
          class="d-flex flex-column flex-md-row justify-content-start justify-content-lg-between py-2 px-4 mb-3"
        >
          <div
            class="d-flex flex-column flex-md-row align-items-start align-items-md-center"
          >
            <div class="form-inline pr-3">
              <div class="form-group">
                <label for="">No. of rows</label>
                <select
                  name=""
                  id=""
                  class="form-control form-control ml-0 ml-sm-2"
                  v-model="perPage"
                  @change="
                    getJobOrderReferrals(DEFAULT_URL, {
                      loader: false,
                    })
                  "
                >
                  <option
                    v-for="option in perPageOptions"
                    :key="option.id"
                    :value="option.value"
                  >
                    {{ option.value }}
                  </option>
                </select>
              </div>
            </div>
            <div class="mr-3">
              <div
                class="d-none d-sm-block position-relative form-group has-search my-auto"
              >
                <span class="bi bi-search form-control-feedback"></span>
                <input
                  type="search"
                  class="form-control w-auto"
                  placeholder="Search"
                />
              </div>
            </div>
            <div class="py-2 p-sm-0">
              <button
                type="button"
                @click="close"
                :disabled="actionLoader || !selected.length"
                class="btn btn-outline-secondary text-dark mr-3"
              >
                Mark as closed
                <span v-if="selected.length" class="mr-1">
                  ({{ selected.length }})</span
                >
                <span
                  v-if="actionLoader"
                  class="spinner-border spinner-border-sm"
                ></span>
              </button>

              <button
                class="btn btn-outline-secondary text-dark"
                @click="
                  getJobOrderReferrals(DEFAULT_URL, {
                    loader: false,
                    tableLoader: false,
                  })
                "
              >
                <i class="bi bi-arrow-repeat"></i>
              </button>
            </div>
          </div>

          <div>
            <div class="d-flex justify-content-right">
              <div class="my-auto mr-2">
                <span
                  >{{
                    `${pagination.from || 0}-${pagination.to || 0} of ${
                      pagination.total || 0
                    }`
                  }}
                </span>
              </div>
              <nav>
                <ul class="pagination mb-0">
                  <li
                    class="page-item"
                    :class="{ disabled: !pagination.prev_page_url }"
                  >
                    <a
                      class="page-link"
                      href="#"
                      @click.prevent="
                        getJobOrderReferrals(pagination.next_page_url, {
                          loader: false,
                        })
                      "
                    >
                      <span aria-hidden="true"
                        ><i class="bi bi-chevron-left"></i
                      ></span>
                      <span class="sr-only">Previous</span>
                    </a>
                  </li>

                  <li
                    class="page-item"
                    :class="{ disabled: !pagination.next_page_url }"
                  >
                    <a
                      class="page-link"
                      href="#"
                      @click.prevent="
                        getJobOrderReferrals(pagination.next_page_url, {
                          loader: false,
                        })
                      "
                    >
                      <span aria-hidden="true"
                        ><i class="bi bi-chevron-right"></i
                      ></span>
                      <span class="sr-only">Next</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table text-nowrap">
            <thead>
              <tr>
                <th class="px-4">
                  <input
                    type="checkbox"
                    style="width: 15px; height: 15px"
                    v-model="checked"
                    @change="checked ? selectAll() : unselectAll()"
                    :disabled="
                      referrals.every((referral) => {
                        return referral.status === 'Closed';
                      })
                    "
                  />
                </th>
                <th scope="col" class="px-4">Referral No.</th>
                <th scope="col" class="px-4">Ticket No.</th>
                <th scope="col" class="px-4">Unit</th>
                <th scope="col" class="px-4">Request Type</th>

                <th scope="col" class="px-4">Created by</th>
                <th scope="col" class="px-4">Created at</th>
                <th scope="col" class="px-4">Status</th>
                <th scope="col" class="px-4">Closed at</th>
                <th scope="col" class="px-4">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr class="text-center" v-if="!referrals.length && !tableLoader">
                <td colspan="9">No results found</td>
              </tr>
              <tr class="text-center" v-if="tableLoader">
                <td colspan="9">
                  <div class="spinner-border spinner-border-sm mr-1"></div>
                  Loading
                </td>
              </tr>
              <tr
                v-for="referral in referrals"
                :key="referral.id"
                class=""
                @click.stop="openViewJobOrderReferralDetailsModal(referral)"
              >
                <td class="px-4">
                  <input
                    type="checkbox"
                    style="width: 15px; height: 15px"
                    @click.stop
                    :value="referral"
                    :disabled="referral.status === 'Closed'"
                    v-model="selected"
                  />
                </td>
                <th class="px-4">{{ referral.id }}</th>
                <th class="px-4">{{ referral.ticket_id }}</th>
                <td class="px-4">{{ referral.unit }}</td>
                <td class="px-4">{{ referral.type }}</td>

                <td class="px-4">{{ referral.creator_last_name }}</td>
                <td class="px-4">
                  {{ referral.date_requested | dateTime }}
                </td>
                <td class="px-4">
                  <span
                    class="badge badge-pill"
                    :class="
                      referral.status === 'Ongoing'
                        ? 'badge-danger'
                        : 'badge-secondary'
                    "
                    >{{ referral.status }}</span
                  >
                </td>
                <td class="px-4">
                  <span v-if="referral.closed_at">{{
                    referral.closed_at | completeDateTime
                  }}</span>
                </td>
                <td class="px-4">
                  <a href="#" @click.prevent="printJobOrderRequest(referral)"
                    ><i class="bi bi-printer"></i
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </CCardBody>
    </CCard>

    <JobOrderRequestTemplate
      v-show="false"
      ref="JobOrderRequestTemplate"
    ></JobOrderRequestTemplate>

    <ViewJobOrderReferralDetailsModal
      ref="ViewJobOrderReferralDetailsModal"
      @updateTable="
        getJobOrderReferrals(DEFAULT_URL, {
          loader: false,
          tableLoader: false,
        })
      "
    ></ViewJobOrderReferralDetailsModal>
  </div>
</template>

<script>
import JobOrderRequestTemplate from "./components/JobOrderRequestTemplate.vue";
import ViewJobOrderReferralDetailsModal from "./components/ViewJobOrderReferralDetailsModal.vue";

export default {
  name: "Main",
  components: {
    JobOrderRequestTemplate,
    ViewJobOrderReferralDetailsModal,
  },
  data() {
    return {
      referrals: [],
      loader: false,
      actionLoader: false,
      perPageOptions: [
        { id: 1, value: 10 },
        { id: 2, value: 25 },
        { id: 3, value: 50 },
        { id: 4, value: 100 },
      ],
      perPage: 10,
      searchValue: "",
      selected: [],
      DEFAULT_URL: "api/support/job-order-referrals",
      pagination: {},
      tableLoader: false,
      checked: false,
    };
  },
  methods: {
    getJobOrderReferrals(page_url, config) {
      page_url = page_url || this.DEFAULT_URL;

      this.loader = config.loader;
      this.tableLoader = config.tableLoader || false;

      axios
        .get(page_url, {
          params: {
            all: false,
            perPage: this.perPage,
          },
        })
        .then((res) => {
          this.makePagination(res.data);
          this.referrals = res.data.data;
        })
        .catch((err) => {
          toastr.error(err.response.data.message, "Server Error");
        })
        .finally(() => {
          this.loader = false;
          this.tableLoader = false;
        });
    },
    makePagination(data) {
      let pagination = {
        current_page: data.current_page,
        last_page: data.last_page,
        next_page_url: data.next_page_url,
        prev_page_url: data.prev_page_url,
        from: data.from,
        to: data.to,
        total: data.total,
      };

      this.pagination = pagination;
    },
    markAsResolved() {
      if (!this.selected.length)
        return toastr.error("No document(s) selected.", "Error");

      if (
        !confirm(
          "Are you sure you want to proceed? This action cannot be undone."
        )
      ) {
        return;
      }

      this.actionLoader = true;

      axios
        .post("api/support/job-order-referrals/close", {
          selected: this.selected,
        })
        .then((res) => {
          toastr.success(res.data.message, "Info");
          this.getJobOrderReferrals(this.DEFAULT_URL, {
            loader: false,
            tableLoader: false,
          });
          this.selected = [];
          this.checked = false;
        })
        .catch((err) => {
          toastr.error(err.response.data.message, "Server Error");
        })
        .finally(() => {
          this.actionLoader = false;
        });
    },
    selectAll() {
      this.selected = this.referrals.filter(
        (ticket) => ticket.status === "Pending"
      );
    },

    unselectAll() {
      this.selected = [];
    },
    printJobOrderRequest(referral) {
      this.$refs.JobOrderRequestTemplate.print(referral);
    },
    openViewJobOrderReferralDetailsModal(referral) {
      this.$refs.ViewJobOrderReferralDetailsModal.open(referral);
    },
    close() {
      axios
        .post("api/support/job-order-referrals/close", {
          selected: this.selected,
        })
        .then((res) => {
          this.getJobOrderReferrals(this.DEFAULT_URL, {
            loader: false,
            tableLoader: false,
          });
        });
    },
  },
  mounted() {
    this.getJobOrderReferrals(this.DEFAULT_URL, {
      loader: true,
      tableLoader: true,
    });
  },
};
</script>

<style scoped>
.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  left: 12px;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  pointer-events: none;
}
</style>
