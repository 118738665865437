<template>
  <div
    id="job-order-request-template"
    style="font-family: Arial, Helvetica, sans-serif"
  >
    <header class="text-center" style="margin-top: 50px">
      <img
        src="@/assets/logo/header.png"
        width=""
        height="100"
        alt="CHED Header Logo"
      />
    </header>

    <div class="text-center" style="margin-bottom: 40px; margin-top: 75px">
      <h2 class="font-weight-bold">JOB ORDER REQUEST FORM</h2>
    </div>

    <div class="px-5 mx-5">
      <table class="table table-bordered" style="">
        <tbody>
          <tr>
            <td width="30%" style="border: 1px solid black !important">
              Referral No.
            </td>
            <th style="border: 1px solid black !important">
              {{ referral.id }}
            </th>
          </tr>
          <tr>
            <td style="border: 1px solid black !important">Ticket No.</td>
            <th style="border: 1px solid black !important">
              {{ referral.ticket_id }}
            </th>
          </tr>
          <tr>
            <td style="border: 1px solid black !important">Requested by</td>
            <th
              colspan=""
              class="text-uppercase"
              style="border: 1px solid black !important"
            >
              {{ referral.creator_first_name }} {{ referral.creator_last_name }}
            </th>
          </tr>
          <tr>
            <td style="border: 1px solid black !important">Unit</td>
            <th
              colspan=""
              style="border: 1px solid black !important"
              class="text-uppercase"
            >
              {{ referral.unit }}
            </th>
          </tr>
          <tr>
            <td style="border: 1px solid black !important">Service Type</td>
            <th
              colspan=""
              class="text-uppercase"
              style="border: 1px solid black !important"
            >
              {{ referral.type }}
            </th>
          </tr>
          <tr>
            <td style="border: 1px solid black !important">Date Requested</td>
            <th
              colspan=""
              class="text-uppercase"
              style="border: 1px solid black !important"
            >
              {{ referral.date_requested | dateTime }}
            </th>
          </tr>
          <tr>
            <td style="border: 1px solid black !important">Description</td>
            <th colspan="" style="border: 1px solid black !important">
              {{ referral.description }}
            </th>
          </tr>
          <tr>
            <td style="border: 1px solid black !important">Remarks</td>
            <th colspan="" style="border: 1px solid black !important">
              {{ referral.remarks }}
            </th>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "JobOrderRequestTemplate",
  data() {
    return {
      referral: new Form({
        id: "",
        ticket_id: "",
        remarks: "",
        unit: "",
        type: "",
        creator_last_name: "",
        creator_first_name: "",
        created_at: "",
        description: "",
        date_requested: "",
      }),
    };
  },
  methods: {
    async print(referral) {
      await this.referral.fill(referral);
      this.$htmlToPaper("job-order-request-template");
    },
  },
};
</script>
